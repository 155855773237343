<script setup lang="ts">
import { useBookingStore } from '~/stores/bookingStore';
const store = useBookingStore();
const { reviews } = storeToRefs(store);
useHead({
	title: 'Diskubre Travel Official Website',
	meta: [
		{
			name: 'description',
			content: 'Book your tours, transportation and activities with Diskubre Travel.',
		},
	],
	script: [
		{
			key: 'jsonld-business-only',
			type: 'application/ld+json',
			children: businessOnly(reviews.value),
		},
	],
});

useSeoMeta({
	title: 'Diskubre Travel Official Website',
	ogTitle: 'Diskubre Travel Official Website',
	description: 'Book your tours, transportation and activities with Diskubre Travel.',
	ogDescription: 'Book your tours, transportation and activities with Diskubre Travel.',
	ogImage: [{ url: 'https://www.diskubrepalawan.com/images/logos/logo.png' }],
	ogSiteName: 'Diskubre Travel',
	ogUrl: 'https://www.diskubrepalawan.com',
});
</script>

<template>
	<div>
		<MainBanner />
		<CurrateCards />

		<BestSeller />
		<!-- <Destinations /> -->
	</div>
</template>
