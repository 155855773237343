<template>
	<div>
		<div class="testimonial-component mini-spacer bg-extra-light">
			<v-container>
				<!-- -----------------------------------------------
            Start Testimonial Text
        ----------------------------------------------- -->
				<v-row justify="center">
					<v-col cols="12" sm="10" md="9" lg="7">
						<div class="text-center">
							<h2 class="section-title font-weight-medium">Our Best Sellers</h2>
							<p class="text-muted">
								Explore Palawan's hidden wonders with our best-selling private tours and transportation services, tailored to offer you a luxurious and personalized journey. From the mystical Underground River to the pristine beaches of El Nido, our expert local guides and top-tier vehicles ensure
								an unforgettable adventure. Choose us for a seamless and customized travel experience in Palawan, where your satisfaction is our priority.
							</p>
						</div>
					</v-col>
				</v-row>
				<v-row class="mt-13" justify="center">
					<v-col cols="12" md="4" sm="6" v-for="item in bestsellers" :key="item.code">
						<listing-item-card :item="item" />
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>
<script setup>
const { data: bestsellers } = await useAsyncData('bestsellers', () => apiGet(`/api/listing/bestseller`));
</script>
